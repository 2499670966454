#topbar {
  @apply bg-white dark:bg-[#212529] text-black dark:text-slate-400 shadow-sm text-lg;

  nav {
    @apply hidden lg:flex;

    ul {
      @apply flex gap-x-12 items-center;
      li {
        @apply hover:text-primary font-gotham font-medium;
        &.active {
          a {
            @apply text-primary;
          }
        }
      }
    }
  }

  .dropdown {
    button {
      @apply bg-transparent border-0 shadow-none text-black dark:text-slate-400 p-0 focus:ring-0 focus:dark:ring-0;
      box-shadow: none !important;
    }

    svg:last-child {
      @apply hidden;
    }

    .py-2 {
      @apply p-0;
    }

    .py-1 {
      @apply py-4 px-6;
    }
  }

  .icons {
    @apply flex items-center gap-x-5 lg:gap-x-8;
  }
}
