table.datatable {
  @apply border-collapse table-fixed w-full text-sm dark:bg-transparent;

  thead {
    th {
      @apply border-b dark:border-slate-700 font-medium font-gotham p-4 pl-8 pt-0 pb-3 text-black dark:text-white text-left;
    }
  }

  tbody {
    @apply bg-white dark:bg-transparent;

    tr {
      @apply transition-all;
      td {
        @apply transition-all border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-black dark:text-slate-400;
      }

      &:hover {
        @apply dark:bg-[#1a1d21] bg-slate-100;
        td {
          @apply dark:text-white;
        }
      }

      &:last-child {
        td {
          @apply border-0;
        }
      }
    }
  }
}
